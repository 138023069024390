import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const BlogPage = ({ data }) => {

  const posts = data.blog.nodes

  return (
    <Layout>
      <Helmet>
        <body className="blog"></body>
      </Helmet>
      <SEO title="Blog" />

      <section className="c-section c-section--pagehead">

        <div className="o-container o-v-middle">
          <div className="o-flex-wrap">
            <h1 className="o-type-6">Blog</h1>
            <a href="https://e4ufd97gowu.typeform.com/to/lJPnKDSr" className="c-button">Join the Wait List</a>
          </div>

        </div>

      </section>
      
      <section className="c-section c-section--gradient">

        <div className="o-container">
          
          <div className="c-posts">

            <div className="o-grid o-grid--cards o-type-2">
              

              {
                posts.map((post, index) => (

                <div key={post.id} className={"o-grid__col c-blog-post " + (index === 0 ? "u-2/2@md" : "u-1/2@md") }>
                  <Link to={post.fields.slug} className="c-blog-post__top">
                    <h2 className="c-blog-post__title">{post.frontmatter.title}</h2>
                    
                    <figure className="c-blog-post__figure"><Img fluid={post.frontmatter.thumbnail.childImageSharp.fluid} alt={post.frontmatter.title} /></figure>
                    
                    <div className={index === 0 ? "c-blog-post__highlighted" : ""}>
                      <div className="c-blog-post__excerpt">
                        <p>{post.frontmatter.excerpt}</p>
                      </div>

                      {index === 0 &&
                      <div className="c-blog-post__bottom">
                        <span className="c-button">Read more...</span>
                      </div>
                      }

                    </div>
                  </Link>

                  { 
                  index !== 0 && 
                  <div className="c-blog-post__bottom">
                    <Link to={post.fields.slug} className="c-button">Read more...</Link> 
                  </div>
                  }

                </div>

                ))
              }

            </div>
          </div>
          

        </div>

      </section>

      
    </Layout>
  )
}

export const query = graphql`
  query {
    blog: allMarkdownRemark(
      filter: { fields: { collection: { eq: "blog" } }, frontmatter: { language: { eq: "English" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
    ) {
      nodes {
        id
        fields {
          slug
        }
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          excerpt
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 1200) {
                presentationHeight
                sizes
                src
                srcSet
                presentationWidth
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPage
